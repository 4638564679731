<template>
  <v-container fluid>
    <v-row style="height: 90vh" align="center" justify="space-between">
      <v-col class="text-center" style="margin-top: -85px">
        <div class="pl-9">
          <v-img
            alt="lecleire"
            class="mx-auto mt-7"
            min-width="420"
            :src="require('@/assets/images/logoLeCleire.png')"
            width="420"
          />
          <div class="mt-6">
            <p class="text-h2">Inicio de sesión</p>
            <p class="text-h5 font-weight-bold pt-2">LeCleire créditos</p>
          </div>
        </div>
      </v-col>

      <v-col>
        <v-row class="mt-n8">
          <v-col cols="9" md="9">
            <v-text-field
              ref="usuario"
              v-model="login_usuario"
              label="Usuario"
              required
              prepend-icon="mdi-account"
              :rules="[rules.required]"
              v-on:keyup.enter="login()"
            ></v-text-field>

            <v-text-field
              class="mt-4"
              ref="password"
              v-model="login_password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="show ? 'text' : 'password'"
              label="Contraseña"
              required
              prepend-icon="mdi-lock"
              persistent-hint
              hint=""
              @click:append="show = !show"
              v-on:keyup.enter="fn_login()"
            ></v-text-field>
            <div class="text-center pt-2">
              <v-btn
                color="#031D6A"
                class="px-8 white--text"
                elevation="2"
                @click="fn_login()"
                >Iniciar sesión</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  data() {
    return {
      show: false,
      rules: {
        required: (v) => !!v || 'Este campo es obligatorio',
      },
      /**
       * 
       */
      //
      password: '',
      showPassword: false,
      /** */
      login_input_type: 'password',
      login_usuario: '',
      login_password: '',
    };
  },
  computed: {
    ...mapState('auth', { loading: 'isAuthenticatePending' }),
  },
  methods: {
    /** */
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    /** */
    ...mapMutations('auth', {
      unsetAuthenticatePending: 'unsetAuthenticatePending',
    }),
    fn_login() {
      console.log('in logging function');
      console.log(this.$refs.usuario);
      //TODO: colocar alertivos para cuando el formulario de inicio de sesion no esta completo
      //
      // let input_usuario = this.$refs.usuario;
      // let input_contrasena = this.$refs.password;
      if (this.login_usuario == '') {
        // input_usuario.style.border = 'thin solid #FF4C25';
      } else if (this.login_password == '') {
        // input_contrasena.style.border = 'thin solid #FF4C25';
      } else {
        //this.$store.state.globalLoading = true;
        //autenticarse
        this.$store
          .dispatch('auth/authenticate', {
            strategy: 'local',
            usuario: this.login_usuario,
            password: this.login_password,
          })
          .then(() => {
            this.$store.state.globalLoading = false;
            this.unsetAuthenticatePending();
            this.$router.push('/');
          })
          .catch((e) => {
            this.$store.state.globalLoading = false;
            /** notificacion de nuevo cliente creado */
            this.$store.dispatch('setSnackbar', {
              show: true,
              text: `Por favor, revisa tus credenciales e inténtalo de nuevo.`,
              color: 'red darken-4',
              timeout: 4500,
            });
            /** crear historico ubicaciones para el analisis */
            console.error(e);
          });
      }
    },
  },
};
</script>
